.resultContainer {
    display: grid;
    grid-template-columns: fit-content(33%) fit-content(33%) fit-content(33%);
    grid-template-rows: auto;
}

.resultSubContainer {
    justify-self: start;
    margin: 0.5em 0.75em;
}

.resultItemContainer {
    border-color: #000;
    border-style: solid;
    border-width: 0em 0.05em 0.05em 0.05em;
    padding: 0.2em 0.5em;
}

.resultItemContainer:first-of-type {
    border-top-width: .05em;
}

.resultItemLabel {
    font-weight: bold;
    font-size: 1.2em;
}

.error {
    color: red;
}

.success {
    color: green;
}