.form {
    display: grid;
    grid-template-columns: 10% 12%;
    grid-template-rows: auto;
    justify-content: start;
    min-width: 75em;
}

.header {
    grid-column: 1 / span 2;
    margin: 0.5em 0.2em;
}

.formComponent {
    align-self: center;
    margin: 0.5em;
    min-width: 14em;
}

.releasedCheckbox {
    min-width: 0;
}

.submitButton {
    font-size: 1em;
    height: 2em;
    justify-self: start;
    margin: 0.5em 0.25em;
    padding: 0;
    width: 5em;
}

.checkboxesHeader {
    align-self: center;
    margin: 0.5em;
    white-space: pre;
}

.checkboxesContainer {
    border-color: #000;
    border-style: solid;
    border-width: 0.05em 0.05em 0.05em 0.05em;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    justify-content: start;
    margin: 0.5em;
    padding: 0.2em 0.5em;
    width: 25%;
    min-width: 4em;
}

.pbuldsCheckboxesContainer {
    min-width: 10em;
    grid-template-columns: 85% 15%;
}

.checkboxesLabel {
    align-self: center;
    grid-column: 1;
    margin: 0.2em;
}

.checkboxesCheckbox {
    align-self: center;
    grid-column: 2;
    margin: 0.2em;
}

.uploadBuildSuccess {
    color: green;
}

.uploadBuildError {
    color: red;
    white-space: pre;
}