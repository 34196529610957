.form {
    display: grid;
    grid-template-rows: auto;
    justify-content: start;
}

.component {
    align-self: center;
    margin: 0.25em;
    padding: 0;
    font-weight: normal;
}

.componentLabel {
    composes: component;
    white-space: pre-wrap;
    font-weight: bold;
}

.submitButton {
    font-size: 1em;
    height: 2em;
    justify-self: start;
    margin: 0.5em 0.25em;
    padding: 0;
    width: 5em;
}

.submitButtonExtended {
    width: fit-content;
}

.checkboxContainer {
    border-color: #000;
    border-style: solid;
    border-width: 0.05em 0.05em 0.05em 0.05em;
    display: grid;
    grid-template-columns: 10% 95%;
    grid-template-rows: auto;
    justify-content: start;
    margin: 0.5em;
    padding: 0.2em 0.5em;
    width: auto;
}

.checkboxCheckbox {
    align-self: center;
    grid-column: 1;
    margin: 0.2em;
}

.checkboxLabel {
    align-self: center;
    grid-column: 2;
    margin: 0.2em;
}

.success {
    color: green;
    white-space: pre;
}

.error {
    color: red;
    white-space: pre;
}