.form {
    display: grid;
    grid-template-rows: auto;
    justify-content: start;
}

.component {
    align-self: center;
    margin: 0.25em;
    padding: 0;
}

.submitButton {
    font-size: 1em;
    height: 2em;
    justify-self: start;
    margin: 0.5em 0.25em;
    padding: 0;
    width: 5em;
}

.error {
    color: red;
    margin: 0.25em;
}