.container {
    background-color: #00293D;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.image {
    align-self: start;
    margin: 1em;
    width: 15em;
}

.logoutButton {
    align-self: center;
    border-radius: 0.3em;
    font-size: 1em;
    height: 2.2em;
    justify-self: end;
    margin-right: 1em;
    width: 7.5em;
}